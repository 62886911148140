import { render, staticRenderFns } from "./side-bar-left.html?vue&type=template&id=46d68eb8&scoped=true&"
import script from "./side-bar-left.vue?vue&type=script&lang=ts&"
export * from "./side-bar-left.vue?vue&type=script&lang=ts&"
import style0 from "./side-bar-left.scss?vue&type=style&index=0&id=46d68eb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d68eb8",
  null
  
)

export default component.exports